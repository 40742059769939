
/**
 * @license
 * Copyright Nico Schett. All Rights Reserved.
 */

/* tslint:disable */
// This file was automatically generated and should not be edited.

import { GraphqlResult } from "@schettnet/bridge/lib/types";
import gql from "graphql-tag";

import BridgeDrop from "./index";

// @ts-ignore
import { deleteSnekSettingsMutation,deleteSnekSettingsMutationVariables,tokenAuthMutation,tokenAuthMutationVariables,verifyTokenMutation,verifyTokenMutationVariables,refreshTokenMutation,refreshTokenMutationVariables,revokeTokenMutation,revokeTokenMutationVariables,jaenRegistrationFormPageMutation,jaenRegistrationFormPageMutationVariables,jaenPublishFormPageMutation,jaenPublishFormPageMutationVariables } from "./types.gen";

// ====================================================
// Bridge operation: doDeleteSnekSettings
// ====================================================

export const doDeleteSnekSettingsMutation = async (
    variables: deleteSnekSettingsMutationVariables
): Promise<GraphqlResult<deleteSnekSettingsMutation>> => {

    const document = gql`mutation deleteSnekSettingsMutation($input: DeleteSnekSettingsInput!){
deleteSnekSettings(input: $input)
}
`;

    return await BridgeDrop.bridge.session.mutate<deleteSnekSettingsMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doTokenAuth
// ====================================================

export const doTokenAuthMutation = async (
    variables: tokenAuthMutationVariables
): Promise<GraphqlResult<tokenAuthMutation>> => {

    const document = gql`fragment userTypeFields_tokenAuthMutation on UserType { __typename
id
username}
fragment obtainJSONWebTokenFields_tokenAuthMutation on ObtainJSONWebToken { __typename
payload
refreshExpiresIn
user { ...userTypeFields_tokenAuthMutation}
token
refreshToken}
mutation tokenAuthMutation($username: String!, $password: String!){
tokenAuth(username: $username, password: $password) { ...obtainJSONWebTokenFields_tokenAuthMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<tokenAuthMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doVerifyToken
// ====================================================

export const doVerifyTokenMutation = async (
    variables: verifyTokenMutationVariables
): Promise<GraphqlResult<verifyTokenMutation>> => {

    const document = gql`fragment verifyFields_verifyTokenMutation on Verify { __typename
payload}
mutation verifyTokenMutation($token: String){
verifyToken(token: $token) { ...verifyFields_verifyTokenMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<verifyTokenMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doRefreshToken
// ====================================================

export const doRefreshTokenMutation = async (
    variables: refreshTokenMutationVariables
): Promise<GraphqlResult<refreshTokenMutation>> => {

    const document = gql`fragment refreshFields_refreshTokenMutation on Refresh { __typename
payload
refreshExpiresIn
token
refreshToken}
mutation refreshTokenMutation($refreshToken: String){
refreshToken(refreshToken: $refreshToken) { ...refreshFields_refreshTokenMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<refreshTokenMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doRevokeToken
// ====================================================

export const doRevokeTokenMutation = async (
    variables: revokeTokenMutationVariables
): Promise<GraphqlResult<revokeTokenMutation>> => {

    const document = gql`fragment revokeFields_revokeTokenMutation on Revoke { __typename
revoked}
mutation revokeTokenMutation($refreshToken: String){
revokeToken(refreshToken: $refreshToken) { ...revokeFields_revokeTokenMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<revokeTokenMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doJaenRegistrationFormPage
// ====================================================

export const doJaenRegistrationFormPageMutation = async (
    variables: jaenRegistrationFormPageMutationVariables
): Promise<GraphqlResult<jaenRegistrationFormPageMutation>> => {

    const document = gql`fragment formErrorFields_jaenRegistrationFormPageMutation on FormError { __typename
name
errors}
fragment jaenRegistrationFormPageMutationFields_jaenRegistrationFormPageMutation on JaenRegistrationFormPageMutation { __typename
result
errors { ...formErrorFields_jaenRegistrationFormPageMutation}}
mutation jaenRegistrationFormPageMutation($token: String, $url: String!, $values: GenericScalar){
jaenRegistrationFormPage(token: $token, url: $url, values: $values) { ...jaenRegistrationFormPageMutationFields_jaenRegistrationFormPageMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<jaenRegistrationFormPageMutation>(
      document,
      variables
    );
}

// ====================================================
// Bridge operation: doJaenPublishFormPage
// ====================================================

export const doJaenPublishFormPageMutation = async (
    variables: jaenPublishFormPageMutationVariables
): Promise<GraphqlResult<jaenPublishFormPageMutation>> => {

    const document = gql`fragment formErrorFields_jaenPublishFormPageMutation on FormError { __typename
name
errors}
fragment jaenPublishFormPageMutationFields_jaenPublishFormPageMutation on JaenPublishFormPageMutation { __typename
result
errors { ...formErrorFields_jaenPublishFormPageMutation}}
mutation jaenPublishFormPageMutation($token: String, $url: String!, $values: GenericScalar){
jaenPublishFormPage(token: $token, url: $url, values: $values) { ...jaenPublishFormPageMutationFields_jaenPublishFormPageMutation}
}
`;

    return await BridgeDrop.bridge.session.mutate<jaenPublishFormPageMutation>(
      document,
      variables
    );
}
